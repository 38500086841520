@import-normalize;
@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url(./fonts/GothamHTF-Bold.otf) format('opentype');
}

:root {
  --blue: #2D2C70;
  --grey: #d8d8d8;
  --med-grey: #cecece;
  --yellow: #F6CB3D;
  --white: #ffffff;
  --soft-white: #ededed;
  font-size: 16px;
  
}
/* Document level adjustments */

@media (max-width: 1400px) {
  :root { font-size: 14px; }
}
@media (max-width: 900px) {
  :root { font-size: 12px; }
}

main {
	position:relative;
	overflow:hidden;
}
body {
	color: #2d2c70;
	background-color: var(--grey);
}

.App {
 	color: var(--blue);
  	text-transform: uppercase;
  	font-family: "Gotham", "Helvetica Neue",
    sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  	margin: 0 auto;
  	position:relative;
}

/*CIRCLES*/
nav:after {
  	content: "";
  	box-sizing: border-box;
  	height: 778px;
  	width: 778px;
  	background-color: var(--med-grey);
  	border-radius: 50%;
  	z-index: 0;
  	align-self: flex-start;
  	position: absolute;
  	z-index: -1;
 	bottom:80px;
  	right: 45px;
}

nav:before {
  	content: "";
  	box-sizing: border-box;
  	height: 778px;
  	width: 778px;
  	border: 144px solid var(--soft-white);
  	border-radius: 50%;
  	z-index: 0;
  	align-self: flex-start;
  	position: absolute;
  	z-index: -1;
  	top: -279px;
    left: -281px;
}

main:before {
  	content: "";
 	box-sizing: border-box;
  	height: 778px;
  	width: 778px;
  	border: 184px solid #f6cb3d;
  	border-radius: 50%;
  	position: absolute;
  	z-index: -1;
  	left: 1338px;
  	top: 154px;
}

/*LINKS*/
a:hover, .borderBottom {
  	border-bottom: 1px solid var(--blue);
}

/* helper classes */
.border {
  	border-top: 1px solid var(--blue);
}

.contain-1680 {
	max-width: 1680px;
    margin: 0 auto;
    padding-right: 40px;
    padding-left: 40px;
}

/*HEADLINE*/
h4, a.calendar {
  	font-size: 0.875rem;
  	letter-spacing: 0.3px;	
  	line-height: 18px;
	color: var(--blue);
}
/*SECTIONS*/

/* NAV */
nav {
	padding-top:39px;
	position: relative;
}
.nav-content {
  	display: flex;
  	flex-direction: row;
  	padding-top: 24px;
  	padding-bottom:45px;
}

nav ul {
  	display: flex;
  	flex-direction: row;
  	justify-content: flex-start;
  	padding-left: 0;
  	flex: 1;
}

nav li {
  	font-size: 1.125rem;
  /*18px*/
  	padding-right: 32px;
  	letter-spacing: 1px;
  	line-height: 24px;
}

.nav-logo {
  	justify-content: flex-end;
  	text-align: right;
  	width:65.25px;
  	height: 65.25px;
  	background-color:#2D2C70;
  	border-radius:50%;
}

.donate {
	padding-right:11px;
	position:relative;
}

.donate:after {
	content: "";
    width: 0;
    height: 0;
    border-top: 3px solid var(--blue);
    border-bottom: 3px solid transparent;
    border-left: 3px solid transparent;
    border-right: 3px solid var(--blue);
    position: absolute;
    top: 6px;
    right: 6px;
}

/*MAIN*/

/* HEADER */
header {
  	display: flex;
  	flex-direction: row;
  	position: relative;
  	padding-bottom: 72px;
  	flex-wrap: wrap;

}

header h1 {
  	flex: 2;
  	margin: -13px 0 0 -16px;
  	line-height: 176px;
  	font-size: 13.63rem;
/*  218px;*/
  	z-index: 1;
}

h1 .our {
  	padding-left: 62.33px;
}

header h3 {
  	align-self: flex-start;
  	text-align: right;
  	flex: 1;
  	color: var(--white);
  	font-size: 3.75rem;
  /*60px;*/
  	letter-spacing: -0.94px;
  	line-height: 54px;
  	text-align: right;
  	max-width: 332px;
}

.header-content {
  	display: flex;
  	flex-direction: row;
  	position: absolute;
  	align-items: baseline;
  	justify-content: space-between;
  	padding-left: 624px;
  	padding-top: 225px;
}

.foundation {
  	font-size: 1rem;
  	max-width: 129px;
  	padding-right: 335px;
  	letter-spacing: 0.89px;
  	line-height: 20px;
}

.calendar {
  	background-color: #ededed;
  	height: 304px;
  	width: 304px;
  	justify-content: center;
  	align-items: center;
  	border-radius: 100%;
  	text-align: center;
  	display: flex;
  	margin: 0;
  	mix-blend-mode: color-dodge;
  	text-decoration: none;
}

a.calendar:hover {
	background-color:var(--blue);
	color:white;
}
/*DOWN ARROW*/
.down-arrow {
  	position: relative;
  	display: block;
  	font-size: 1.5rem;
  	font-weight: bold;
  	text-decoration: none;
  	color: #2D2C70;
}
.down-arrow:after, .down-arrow:before {
  	content: "";
  	display: block;
  	position: absolute;
  	transition: all 0.5s ease-in-out;
}
.down-arrow:before {
  	bottom: 0;
  	right: 1em;
  	width: 1em;
  	height: 1em;
  	-webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  	-webkit-transform: translate(0, -0.5em) rotate(135deg);
          transform: translate(0, -0.5em) rotate(135deg);
  	box-shadow: inset -.15em .15em 0 0 #2D2C70;
}
.down-arrow:after {
  	bottom: 22px;
  	right: 1em;
  	width: 1em;
  	height: 0.15em;
  	-webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  	background-color: #2D2C70;
}
.down-arrow:hover:before {
  	-webkit-transform: translate(0, 1em) rotate(135deg);
          transform: translate(0, 1em) rotate(135deg);
}
.down-arrow:hover:after {
  	-webkit-transform: translate(0, 1.5em) rotate(90deg);
          transform: translate(0, 1.5em) rotate(90deg);
}

/* FEATURES */
.feature {
 	display: flex;
  	flex-direction: row;
  	position:relative;
  	flex-wrap: wrap;
}

.featureCardContainer {
  	list-style: none;
    margin: 0;
    padding: 0;
    flex: 2;
    display: flex;
    flex-direction: column;
    position: relative;
}

.featureCard {
  	display: flex;
  	flex-direction: row;
  	padding: 24.5px 0 80px 0;
  	flex-wrap: wrap;
}


.listItem0 {
	order:1;
}
.listItem2 {
	order:2;
}
.listItem1 {
	order:3;
}

.listItem3 {
	order:4;
}
.listItem4 {
	order:5;
}


.featureContent {
  	display: flex;
  	flex-direction: column;
  	flex: 1;
}

.feature h4 {
  	flex: 1;
  	max-width: 144px;
  	padding-right: 58px;
  	margin-top: 23.5px;
}

.featureContent h5 {
  	flex: 1;
  	margin: 0;
  	width: 352px;
  	font-size: 	2rem;
  	/*32px;*/
  	font-weight: bold;
  	line-height: 32px;
}

.feature-details {
  	display: flex;
  	flex-direction: row;
  	justify-content: space-between;
}

.featureContent p {
  	margin: 0;
  	font-size: 	0.875rem;
  	/*14px;*/
  	font-weight: bold;
  	letter-spacing: 0.3px;
  	line-height: 18px;
}
.featureContent p:first-of-type {
  	max-width: 352px;
}

.featureContent p:last-of-type {
  	width: 230px;
  	padding-right: 100px;
}

.feature:before {
  	content: "";
    box-sizing: border-box;
    height: 400px;
    width: 400px;
    background-color: #f6cb3d;
    border-radius: 50%;
    z-index: 0;
    align-self: flex-start;
    position: absolute;
    z-index: -1;
    bottom: 518px;
    right: 342px;

}

.featureCardContainer:before {
  	content: "";
    box-sizing: border-box;
    height: 248px;
    width: 248px;
    background-color: var(--med-grey);
    border-radius: 50%;
    z-index: 0;
    align-self: flex-start;
    position: absolute;
    z-index: -1;
   	top: 311px;
    left: -135px;

}
.feature ul:after {
  	content: "";
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 0;
    align-self: flex-start;
    position: absolute;
    z-index: -1;
   	top: 477px;
    left: -178px;
    height: 120px;	
    width: 120px;	
    background-color: #82C5B7;
    mix-blend-mode: difference;
}
/* LIVESTREAM */

.livestream:before {
	border-top: 1px solid var(--blue);
	z-index:2;
	content:"";
}

.livestream .arrow-text {
	display:none;
}

.livestream a:after {
  	content:"";
  	width: 0; 
  	height: 0; 
  	border-top: 11.5px solid transparent;
  	border-bottom: 11.5px solid transparent;
  	border-left: 17px solid var(--blue);
}

.livestream a:hover {
	background-color: var(--yellow);
	text-decoration:none;
	border:none;
}

.livestream h4 {
  	flex: 1;
  	max-width: 144px;
  	margin-top: 23.5px;
  	padding-bottom:46px;
}

.livestream h2 {
  	color: #ffffff;
  	font-size: 11rem;
/*  176px;*/
  	line-height: 207px;
  	text-align: center;
  	letter-spacing: -4px;
}

.livestream {
  	display:flex;
  	flex-direction: column;
    padding-bottom: 86px;
}

.livestream-content {
  	display:flex;
  	flex-direction:row;
  	align-items: center;
  	justify-content: space-between;
  	position:relative;
  	flex-wrap:wrap;
}

.livestream a {
	color: #2d2c70;
  	text-decoration: none;
  	font-size: 	1.0625rem;
  	/*17px;*/
  	background-color: var(--med-grey);
  	height: 704px;
  	width: 704px;
  	justify-content: center;
  	align-items: center;
  	border-radius: 100%;
  	display: flex;
  	mix-blend-mode: color-dodge;
	position: absolute;
  	left: 25%;
}

